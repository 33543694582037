import React from 'react'
import Layout from '../../../components/layout'
import RecommendedPageComponent from "../../../components/pages/recommended";

const embedded = true;
const RecommendedPageEmbedded = () => {
  return (
    <Layout embedded={embedded}>
      <RecommendedPageComponent embedded={embedded} webViewVersion="v1" />
    </Layout>
  )
}

export default RecommendedPageEmbedded
